// 公共样式
:global {
  /**最大宽度**/
  .page-max-width {
    max-width: 1845px;
    margin: 0 auto;
    padding: 0 50px;
    box-sizing: border-box;
    padding-bottom: 50px;
  }

  /**字体大小**/
  .com-fz12 {
    font-size: 12px;
  }
  .com-fz14 {
    font-size: 14px;
  }
  .com-fz16 {
    font-size: 16px;
  }
  .com-fz18 {
    font-size: 18px;
  }
  .com-fz20 {
    font-size: 20px;
  }

  /**字体weight**/
  .com-fw-normal {
    font-weight: normal;
  }
  .com-fw-bold {
    font-weight: bold;
  }
  /** 宽 高 **/
  .com-w100 {
    width: 100%;
  }
  .com-h100 {
    height: 100%;
  }
  /** 鼠标 **/
  .com-cursor-p {
    cursor: pointer;
  }
  .com-cursor-not {
    cursor: not-allowed;
  }
  .com-cursor-text {
    cursor: text;
  }
  /** 布局 **/
  .g-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
  }
  .g-flex-inline {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: inline-flex;
  }
  .g-flex-wap {
    -webkit-box-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .g-flex-wap-start {
    -webkit-box-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .g-flex-v {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .g-flex-rr {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .g-flex-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .g-flex-v-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .g-flex-v-top {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  .g-flex-v-bottom {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }

  .g-flex-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .g-flex-around {
    -webkit-box-pack: center;
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .g-flex-end {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .g-flex-v-between {
    -webkit-align-content: space-between;
    align-content: space-between;
  }

  .g-flex-c1 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
  }
  .g-block {
    display: block;
  }
  .g-inline-block {
    display: inline-block;
  }
  /** 对齐方式 **/
  .g-ta-rt {
    text-align: right;
  }
  .g-ta-lt {
    text-align: left;
  }
  .g-ta-ct {
    text-align: center;
  }
  .g-va-middle {
    vertical-align: middle;
  }
  .g-va-top {
    vertical-align: top;
  }
  .g-va-bottom {
    vertical-align: bottom;
  }
  /** 定位 **/
  .g-pos-rt {
    position: relative;
  }

  .g-pos-as {
    position: absolute;
  }

  .g-pos-fx {
    position: fixed;
  }
  /* overflow */
  .g-of-auto {
    overflow: auto;
  }
  .g-of-auto-y {
    overflow-y: auto;
  }
  .g-of-auto-x {
    overflow-x: auto;
  }
  .g-of-hidden {
    overflow: hidden;
  }
  .g-of-hidden-y {
    overflow-y: hidden;
  }
  .g-of-hidden-y {
    overflow-y: hidden;
  }
  /* ------浮动------*/
  .g-clearfix::after {
    display: block;
    clear: both;
    content: '';
  }
  .g-float-lf {
    float: left;
  }
  .g-float-rh {
    float: right;
  }
  /** 盒子模型 **/
  .g-bs-border {
    box-sizing: border-box;
  }
  .g-bs-content {
    box-sizing: content-box;
  }
  .g-ellipsis1 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: normal;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* max-height line-height */
  }
  .g-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .scroll-bar {
    //滚动条样式
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 6px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #dcdcdc;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      background: #f4f4f7;
    }
  }
  //margin
  .m-xs {
    margin: 5px;
  }

  .m-sm {
    margin: 10px;
  }

  .m {
    margin: 15px;
  }

  .m-md {
    margin: 20px;
  }

  .m-lg {
    margin: 30px;
  }

  .m-xl {
    margin: 50px;
  }

  .m-none {
    margin: 0;
  }

  .m-t-xs {
    margin-top: 5px;
  }

  .m-t-sm {
    margin-top: 10px;
  }

  .m-t {
    margin-top: 15px;
  }

  .m-t-md {
    margin-top: 20px;
  }

  .m-t-lg {
    margin-top: 30px;
  }

  .m-t-xl {
    margin-top: 40px;
  }

  .m-b-xs {
    margin-bottom: 5px;
  }

  .m-b-sm {
    margin-bottom: 10px;
  }

  .m-b {
    margin-bottom: 15px;
  }

  .m-b-md {
    margin-bottom: 20px;
  }

  .m-b-lg {
    margin-bottom: 30px;
  }

  .m-b-xl {
    margin-bottom: 40px;
  }

  .m-l-xs {
    margin-left: 5px;
  }

  .m-l-sm {
    margin-left: 10px;
  }

  .m-l {
    margin-left: 15px;
  }

  .m-l-md {
    margin-left: 20px;
  }

  .m-l-lg {
    margin-left: 30px;
  }

  .m-l-xl {
    margin-left: 40px;
  }

  .m-r-xs {
    margin-right: 5px;
  }

  .m-r-sm {
    margin-right: 10px;
  }

  .m-r {
    margin-right: 15px;
  }

  .m-r-md {
    margin-right: 20px;
  }

  .m-r-lg {
    margin-right: 30px;
  }

  .m-r-xl {
    margin-right: 40px;
  }

  //padding
  .padding-xs {
    padding: 5px;
  }

  .padding-sm {
    padding: 10px;
  }

  .padding {
    padding: 15px;
  }

  .padding-md {
    padding: 20px;
  }

  .padding-lg {
    padding: 30px;
  }

  .padding-xl {
    padding: 50px;
  }

  .padding-none {
    padding: 0;
  }

  .padding-t-xs {
    padding-top: 5px;
  }

  .padding-t-sm {
    padding-top: 10px;
  }

  .padding-t {
    padding-top: 15px;
  }

  .padding-t-md {
    padding-top: 20px;
  }

  .padding-t-lg {
    padding-top: 30px;
  }

  .padding-t-xl {
    padding-top: 40px;
  }

  .padding-b-xs {
    padding-bottom: 5px;
  }

  .padding-b-sm {
    padding-bottom: 10px;
  }

  .padding-b {
    padding-bottom: 15px;
  }

  .padding-b-md {
    padding-bottom: 20px;
  }

  .padding-b-lg {
    padding-bottom: 30px;
  }

  .padding-b-xl {
    padding-bottom: 40px;
  }
  .padding-l-xs {
    padding-left: 5px;
  }

  .padding-l-sm {
    padding-left: 10px;
  }

  .padding-l {
    padding-left: 15px;
  }

  .padding-l-md {
    padding-left: 20px;
  }

  .padding-l-lg {
    padding-left: 30px;
  }

  .padding-l-xl {
    padding-left: 40px;
  }
  .padding-r-xs {
    padding-right: 5px;
  }

  .padding-r-sm {
    padding-right: 10px;
  }

  .padding-r {
    padding-right: 15px;
  }

  .padding-r-md {
    padding-right: 20px;
  }

  .padding-r-lg {
    padding-right: 30px;
  }

  .padding-r-xl {
    padding-right: 40px;
  }
  .underline-b {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, #d9d9d9 50%, #d9d9d9 50%, transparent 0%);
    background-size: 18px 1px;
    background-repeat: repeat-x;
  }
  .backtop-icon {
    width: 50px;
    height: 50px;
  }
  .backtop-icon-svg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .i-rank {
    display: inline-block;
    vertical-align: middle;
    color: #ffffff;
    background: #d3d3d3;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 14px;
  }
  .i-rank-first {
    background: #d7584f;
  }
  .i-rank-sec {
    background: #e98033;
  }
  .i-rank-thr {
    background: #fdd367;
  }
  .page-right {
    width: 508px;
    min-width: 508px;
  }
  .page-new-right {
    width: 627px;
    min-width: 627px;
  }
  .g-hidden {
    visibility: hidden;
  }
  .red-point {
    display: inline-block;
    background-color: red;
    border: 4px solid red;
    border-radius: 50%;
  }
  .big-long-btn {
    width: 312px !important;
    border-radius: 4px !important;
    height: 44px !important;
    line-height: 44px !important;
  }
  .line-height-big {
    line-height: 34px !important;
  }
  .artcle-tag {
    display: inline-block;
    vertical-align: middle;
    background: #333333;
    color: #ffe097;
    height: 20px;
    line-height: 20px;
    text-align: center;
    width: 60px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: bold;
  }
  .artcle-tag-two {
    letter-spacing: 5px;
    padding: 0 10px 0 15px;
    width: auto;
    white-space: nowrap;
  }
  .mfyx-label-img{
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
  }
  .mfyx-label-normal {
    height: 20px;
    line-height: 20px;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
  }
  .mfyx-label-article {
    background: linear-gradient(90deg, #fff6dd -0.77%, #fff 99.87%);
    color: #faa12f;
  }
  .mfyx-label-kd {
    background: linear-gradient(90deg, #ff9c41 -0.77%, rgba(255, 156, 65, 0) 99.87%);
    color: #333333;
  }
  .mfyx-label-wbzy {
    background: linear-gradient(90deg, #8fa4c4 -0.77%, rgba(143, 164, 196, 0) 99.87%);
    color: #333333;
  }
  .tag-label {
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    padding: 0 4px;
    height: 22px;
    line-height: 22px;
    display: inline-block;
    margin-right: 5px;
    font-weight: bold;
    vertical-align: bottom;
  }
  .spec-card-tag {
    display: inline-block;
    background: #f2e8cc;
    color: #928563;
    height: 22px;
    line-height: 22px;
    text-align: center;
    width: 65px;
    border-radius: 12px;
    margin-right: 5px;
  }
  .card-tag-mfyx {
    background: linear-gradient(90deg, #fa9e32 0%, #ffbf60 100%);
    color: #ffffff;
  }
  .research-point {
    background: linear-gradient(90deg, #f79158 0.01%, #da4343 100%);
    color: #ffffff;
  }
  .ext-right {
    background: linear-gradient(90deg, #8fadf2 0.01%, #aab4cc 100%);
    color: #32435c;
  }
  .card-tag-lyhf {
    border-radius: 100px;
    background: #e5efff;
    color: #faa12f;
    font-size: 12px;
    line-height: 13px;
    display: inline-block;
    padding: 4px 10px;
    margin-right: 5px;
    font-weight: 400;
  }
  @media screen and (max-width: 1360px) and (min-width: 1024px) {
    .page-right {
      width: 430px;
      min-width: 430px;
    }
    .page-new-right {
      width: 430px;
      min-width: 430px;
    }
  }

  @media screen and (max-width: 1024px) {
    .page-right {
      width: 430px;
      min-width: 430px;
    }
    .page-new-right {
      width: 430px;
      min-width: 430px;
    }
    .page-max-width {
      padding: 0 20px;
      padding-bottom: 20px;
    }
  }
  // 错误状态码的弹框
  .all-dlg-style {
    .ant-modal-confirm-content {
      margin: 20px 0 30px;
    }
    .ant-modal-confirm-btns {
      display: flex;
      justify-content: center;
    }
    .ant-btn-primary {
      margin-left: 30px;
      background-color: #faa12f;
    }
  }
}
